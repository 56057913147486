import { useState, useEffect } from 'react';

/**
 * Retrieve the screen size dimensions from the window object
 *
 * @return  {object}  object containing the innerWidth and innerHeight values from window
 */
const getWindowDimensions = () => {
	const isBrowser = typeof window !== 'undefined';
	if (isBrowser) {
		const { innerWidth: width, innerHeight: height } = window;
		return {
			width,
			height,
		};
	}
};

/**
 * React hook to retrieve the window dimensions on load and
 * screen resize event listeners
 *
 * @return  {object}  object containing the screen width and height sizes
 */
export const useWindowDimensions = () => {
	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowDimensions;
};
