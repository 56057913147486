import React from 'react';
import PropTypes from 'prop-types';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { Head } from '../components/templates/head';
import { VideoCallForm } from '../components/organisms/video-call-form';
import { NextStepHeader } from '../components/organisms/next-step-header';
import { Button } from '../components/atoms/button';
import { CloseButton } from '../components/atoms/close-button';

import LeftArrowIcon from '../assets/left-arrow.svg';
import RestartIcon from '../assets/restart.svg';

// markup
const VideoCallPage = ({ location }) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
					}
				}
			}
		`
	);

	const handleBackTracking = () => {
		const hasGtag = typeof window.gtag === 'function';

		if (!hasGtag) return;

		window.gtag('event', 'click', {
			click_text: 'Back',
			click_type: 'button',
		});
	};

	return (
		<GoogleReCaptchaProvider
			reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}
		>
			<Head title={site.siteMetadata.title} />
			<main id="main" className="flex flex-col min-safe-h-screen">
				<NextStepHeader
					title="Book a video call"
					subtitle="Make sure you’ve a brew ready. Don’t worry, you can always
					camera-off!"
					backBtn={
						<Button
							variant="White"
							onClick={() => {
								handleBackTracking();
								if (location?.state?.backRoute) {
									return navigate(location.state.backRoute);
								}
								return navigate('/answers', {
									state: location.state,
								});
							}}
							className="flex items-center !py-xxs-f !px-l-f"
						>
							<LeftArrowIcon className="mr-2 max-w-none" />
							Back
						</Button>
					}
					restartBtn={
						<CloseButton
							className="!py-xxs-f !px-l-f"
							destination="/"
							promptTitle="Restart Triage"
							promptDesc="Are you sure you want to restart? You will lose all information added so far."
							id="videoRestart"
						>
							<RestartIcon className="mr-2" />
							Restart
						</CloseButton>
					}
				/>

				<VideoCallForm
					answers={location.state ? location.state.answers : null}
				/>
			</main>
		</GoogleReCaptchaProvider>
	);
};

VideoCallPage.defaultProps = {
	location: undefined,
};

VideoCallPage.propTypes = {
	// eslint-disable-next-line react/forbid-prop-types
	location: PropTypes.object,
};

export default VideoCallPage;
