import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useWindowDimensions } from '../../../utils/screen-resize';

export const OverflowScroll = ({
	title,
	items,
	className,
	headerLevel,
	borderBetween,
	centreDesktop,
	isHidden,
}) => {
	const listRef = useRef(null);
	const [overflowActive, setOverflowActive] = useState(false);
	const Heading = headerLevel;

	let browserWidth = 0;
	const isBrowser = typeof window !== 'undefined';
	if (isBrowser) {
		const { width } = useWindowDimensions();
		browserWidth = width;
	}

	useEffect(() => {
		const isOverflown = (element) =>
			element.scrollHeight > element.clientHeight ||
			element.scrollWidth > element.clientWidth;

		setOverflowActive(isOverflown(listRef.current));
	}, [setOverflowActive, browserWidth, isHidden]);

	return (
		<div className={className}>
			{title ? (
				<Heading
					className={clsx(
						overflowActive ? null : 'text-center',
						'mb-base-f font-centra-medium text-lg-f'
					)}
				>
					{title}
				</Heading>
			) : null}
			<ul
				className={clsx(
					'flex',
					overflowActive ? 'overflow-x-scroll w-screen' : null,
					centreDesktop && !overflowActive ? 'justify-center' : null
				)}
				ref={listRef}
			>
				{items.map((item, index, arr) => (
					<li
						key={item.key}
						className={clsx(
							borderBetween ? 'border-r-1 border-blue-200' : null,
							borderBetween && arr.length - 1 === index
								? 'border-none'
								: null,
							borderBetween ? 'px-xs-f' : null,
							'flex-shrink-0'
						)}
					>
						{item}
					</li>
				))}
			</ul>
		</div>
	);
};

OverflowScroll.defaultProps = {
	title: '',
	className: '',
	headerLevel: 'h2',
	borderBetween: false,
	centreDesktop: false,
	isHidden: false,
};

OverflowScroll.propTypes = {
	title: PropTypes.string,
	items: PropTypes.arrayOf(PropTypes.node).isRequired,
	className: PropTypes.string,
	headerLevel: PropTypes.oneOf(['h2', 'h3', 'h4', 'h5', 'h6']),
	borderBetween: PropTypes.bool,
	centreDesktop: PropTypes.bool,
	isHidden: PropTypes.bool,
};
